const apiConstants = {
    "addScenarioOnTheFly": "/add_scenario_on_the_fly",
    "allStorageLocations": "/storage-locations",
    "baseUrl": "",
    "binWidth": "/bin_width",
    "charts": {
        "baseUrl": "/charts",
        "calculationStatus": "/calc-mode",
        "costPerUnit": "/cost-perunit",
        "dohBenchmarking": "/doh-benchmarking",
        "histogram": {
            "baseUrl": "/histograms/:name",
            "download": "/download"
        },
        "historicalInventory": "/historical-inventory?unit_of_measurement=:unit_of_measurement",
        "inventoryCoverageDevelopment": "/inventory-coverage-development",
        "obsoleteWaterfall": "/obsolete-waterfall",
        "onHandVsRiskPeriodMatrix": "/on_hand-vs-risk_period-matrix?col_x=:col_x&col_y=:col_y",
        "overviewWaterfall": "/overview-waterfall",
        "rapidDiagnostics": {
            "AvgInventoryVsClientAvgTarget": "/avg-inventory-vs-client-avg-target",
            "CurrentInventoryVsClientMinMaxTarget": "/current-inventory-vs-client-min-max-target",
            "baseUrl": "/rapid-diagnostics-waterfall"
        },
        "rapidDiagnosticsSummary": "/rapid-diagnostics-summary",
        "replenishmentStrategy": "/replenishment-strategy",
        "safetystockModel": "/safetystock-model",
        "serviceLevelMatrix": "/service-level-matrix?col_x=:col_x&col_y=:col_y",
        "targetByCategory": "/target-category",
        "targetStockBreakdown": "/target-stock-breakdown",
        "waterfallImprovement": "/improvement-waterfall"
    },
    "chat": {
        "baseUrl": "/chat",
        "delete": "?user_email=:user_email",
        "feedback": "/feedback",
        "get": "?limit=:limit&offset=:offset&user_email=:user_email"
    },
    "config": "/config",
    "configuration": "/configuration",
    "cycle": {
        "active": "?active=true",
        "baseUrl": "/cycle",
        "details": "/:id"
    },
    "deadStockDetails": "/dead-stock",
    "exportChatFeedback": "/export-chat-feedback",
    "files": {
        "baseUrl": "/files",
        "move": "/move",
        "preSignedUrl": "/pre-signed-url",
        "upload": "/upload"
    },
    "inventoryHealth": {
        "baseUrl": "/inventory-health",
        "top": "/top"
    },
    "inventoryTargetAdherence": "/inventory-target-adherence",
    "issues": {
        "baseUrl": "/issues",
        "download": "/download?user_email=:user_email",
        "summary": "/summary"
    },
    "jobs": {
        "baseUrl": "/jobs",
        "details": "/:id",
        "latest": "/latest",
        "statistics": "/statistics/summary",
        "statusLogs": "/status-logs"
    },
    "leadTimeDerivations": "/lead-time-deviations",
    "overrides": {
        "baseUrl": "/overrides",
        "download": "/download",
        "upload": "/upload"
    },
    "questionnaire": {
        "assessmentsList": "/assessments",
        "assignOrUnAssignUser": "/assessments/:assessmentGroupId",
        "baseUrl": "/questionnaire",
        "currentAssessment": "/current-assessment",
        "currentAssessmentForSuperAdmin": "/assessment-users-group/:assessmentGroupId",
        "deleteAssessment": "/assessments/:assessmentGroupId",
        "detailedResults": "/detailed-results?assessment_user_group_id=:assessmentGroupId",
        "getUsers": "/assessments/users?user_email=:user_email",
        "saveAssessment": "/assessments",
        "saveChoices": "/questionnaire_choices",
        "saveQuestions": "/questionnaire",
        "saveRecommendations": "/recommendations/:assessmentGroupId",
        "selectAnswer": "/select-answer/:assessmentGroupId",
        "submitForReview": "/assessments/:assessmentGroupId/submit",
        "summary": "/benchmark?assessment_user_group_id=:assessmentGroupId"
    },
    "scenarios": {
        "baseUrl": "/scenarios",
        "details": {
            "baseUrl": "/:id",
            "getOrAddRule": "/scenario-rule",
            "results": {
                "baseUrl": "/results",
                "download": "/download",
                "paginated": "?limit=:limit&offset=:offset"
            },
            "run": "/run",
            "scenarioConfig": {
                "baseUrl": "/scenario-settings",
                "withBuildInScenario": "?build_on_scenario_id=:build_on_scenario_id"
            },
            "scenarioConfigurations": {
                "baseUrl": "/scenario-configurations",
                "details": "/:config_id",
                "download": "/download",
                "upload": "/upload"
            }
        },
        "download": "/download",
        "results": {
            "baseUrl": "/results",
            "download": "/download"
        },
        "upload": "/upload"
    },
    "sku": {
        "baseUrl": "/sku",
        "id": {
            "baseUrl": "/:sku_id",
            "locationId": {
                "appliedScenarioConfigurations": "/applied-scenario-configurations",
                "baseUrl": "/location/:location_id",
                "editOnTheFly": "/edit-on-the-fly",
                "forecast": "/forecast",
                "multipleSimulation": "/multiple-simulation",
                "poData": {
                    "baseUrl": "/po-data",
                    "download": "?type=download&user_email=:user_email"
                },
                "simulation": {
                    "baseUrl": "/simulation",
                    "download": "/download",
                    "projection": "?projection=:projection"
                }
            },
            "locations": "/locations"
        },
        "search": "/search"
    },
    "skuLocation": {
        "baseUrl": "/sku-location",
        "count": "/count",
        "details": {
            "appliedScenarioConfiguration": "applied-scenario-configurations",
            "baseUrl": "/:id/",
            "editOnTheFly": "edit-on-the-fly",
            "forecast": {
                "baseUrl": "forecast"
            },
            "multipleSimulation": "multiple-simulation",
            "poData": {
                "baseUrl": "po-data",
                "download": "?type=download&user_email=:user_email"
            },
            "simulation": {
                "baseUrl": "simulation",
                "download": "/download",
                "getSimulations": {
                    "baseUrl": "?user_email=:user_email"
                }
            }
        },
        "search": "/search"
    },
    "storageLocations": {
        "baseUrl": "storage-locations",
        "sku": "/sku/:id"
    },
    "system": {
        "baseUrl": "/system",
        "dataModel": "/data-model",
        "downloadTemplate": "/download-template/:type?file_type=:file_type",
        "exportData": "/export-data",
        "filterData": "/filter-data",
        "publishEvent": "/publish-event",
        "tenant": "/tenants"
    },
    "targetOverview": "/target-overview",
    "targetOverviewDetails": "/target-overview-details",
    "timeSeries": {
        "baseUrl": "/time-series",
        "overview": "/overview"
    },
    "transformerConfig": "/transformer_config",
    "translations": "/system/translations/:language/:namespace"
};

/**
 * Replace Params key in url with value
 * @param {string} urlPart - Part of the url
 * @param {object} urlParams - Url params
 * @returns {string} - Url with params key replaced
 */
function replaceParams(urlPart, urlParams) {
    // Replace all url params
    Object.keys(urlParams).forEach((key) => {
        urlPart = urlPart.replace(`:${key}`, urlParams[key]);
    });

    // Check if all params were provided, else return empty string
    const urlParts = urlPart.split(/:[A-Za-z]*\//);
    if (urlParts.length > 1) {
        throw Error("All url params not provided");
    }

    return urlPart;
}

/**
 * Parses the key, fetches url partials and generates url.
 * @param {object} obj - Base object
 * @param {Array} keyArr - Array of keys
 * @param {number} index - Index no
 * @param {object} urlParams - JSON Object of url parameters
 * @returns {string} - API Url with url params
 */
function urlMaker(obj, keyArr, index, urlParams) {
    try {
        if (index === keyArr.length) {
            // If last key is an object, then select baseUrl of the object
            if (typeof obj === "object") {
                obj = obj.baseUrl || "";
            }

            return replaceParams(obj, urlParams);
        }
        const urlPart = replaceParams(obj.baseUrl || "", urlParams);

        return urlPart + urlMaker(obj[keyArr[index]], keyArr, ++index, urlParams);
    } catch (ex) {
        // eslint-disable-next-line no-console
        console.error("Error while generating url - ", ex);
    }

    return null;
}

/**
 * Construct url by replacing url params and adding query params
 * @param {*} apiName - Name of api to get url
 * @param {*} urlParams - (Optional) - List of params
 * @param {*} encode - Whether to encode url params
 * @returns {string} - API Url with url params
 */
export const serviceUrls = (apiName, urlParams = {}, encode = true) => {
    if (urlParams && encode) {
        Object.keys(urlParams).forEach((param) => {
            urlParams[param] = encodeURIComponent(urlParams[param]);
        });
    }

    return urlMaker(apiConstants, apiName.split("."), 0, urlParams);
};
